import React from 'react'
import './footer.scss'
import  logo  from '../../Images/logo_entire_white.svg';

export default function Footer() {
  return (
    <div className="footer">
      <img src={logo} alt="logo_entire" />
          DGR Excavating © 2022 
    </div>
  )
}
