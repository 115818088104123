import React from 'react'
import DesktopNav from './Desktop/DesktopNav'
import MobileNav from './Mobile/MobileNav'
import './navigation.scss'

export default function Navigation() {
  return (
    <div className='navigation'>
        <MobileNav />
        <DesktopNav />
    </div>
  )
}
