import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import logo from '../../../Images/DGR_Logo_1.svg'

export default function MobileNav() {
  return (
    <div className="mobile">
      <ul>
        <li><img src={logo} alt="dgr_logo" /></li>
      </ul>
        <GiHamburgerMenu />
    </div>
  )
}
