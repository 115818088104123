import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer';
import Main from '../../Components/Layout/Main'
import Navigation from '../../Components/Navigation/Navigation';
import './home.scss';

export default function Home() {
  return (
    <div>
      <Navigation />
      <div className="home-wrapper">
        <div className='home-content-wrapper'>
          <div className='home-cta'>
              <h2>This website is under development</h2>
              <p>For all inquiries, feel free to email me at:  <a href="mailto:syme@dgrexcavating.com">syme@dgrexcavating.com</a></p>
          </div>
        </div>
      </div>
      <div className="home-overview">
        <div className="seperator">
          <h2>Residential & Commercial excavating services in Pennsylvania</h2>
        </div>
        <div className='home-overview-content'>
            <div className='card'>
              <h3>Excavation</h3>
              <p>We make excavation easy. No project is too small or too big.</p>
            </div>
            <div className='card'>
              <h3>Demolition</h3>
              <p>We make excavation easy. No project is too small or too big.</p>
            </div>
            <div className='card'>
              <h3>Land Clearing</h3>
              <p>We make excavation easy. No project is too small or too big.</p>
            </div>
          </div>
      </div>
      <Footer />
    </div>


  )
}
