import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../Images/DGR_Logo_1.svg'
import {FaChevronDown} from 'react-icons/fa'
export default function DesktopNav() {
  return (
    <div className="desktop">
      <ul>
      <li><img src={logo} alt="dgr_logo" /></li>
      </ul>
      <ul>
        <li><Link to="/">Contact </Link></li>
        <li><Link to="/">About DGR <FaChevronDown /></Link></li>
        <li><Link to="/">Services <FaChevronDown /></Link></li>
        <li><Link to="/">Projects <FaChevronDown /></Link></li>
      </ul>
    </div>
  )
}
